<template>
  <div>
    <v-dialog v-model="showDialog" width="1000">
      <v-card class="pa-16" style="background-color: #F2F5FF">
        <h2 style="color: #263B4F" class="text-center mb-7">
          Appeal Letter
        </h2>
        <v-textarea
          v-model="letter"
          outlined
          rows="20"
          placeholder="Enter your text here"
        />
        <v-data-table
          :items="mandates"
          v-if="radioRow == 3"
          v-model="selectedMandates"
          item-key="id"
          show-select
          class="elevation-1"
          mobile-breakpoint="0"
          disable-pagination
          :headers="headers" hide-default-footer
        >
          <template v-slot:item.mandate="{ item }">
            <v-chip color="primary" dark>{{ item.mandate }}</v-chip>
          </template>
        </v-data-table>
        <v-card-actions class="d-flex justify-space-around">
          <v-btn color="error" @click="showDialog = false">Close</v-btn>
          <v-btn color="primary" :loading="generatingLetters" @click="generatePdf">Generate PDF</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="appeal.appeal_status_id === 2">
      <v-col>
        <v-alert type="info">
          <span class="font-weight-bold">Appeal Approved. </span> The workflow for the client is ended.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="appeal.appeal_status_id === 3">
      <v-col>
        <v-alert type="warning">
          <span class="font-weight-bold">Appeal Denied. </span> Client placed in a "Litigation Hold" status.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex align-center" style="gap: 5px">
        <v-icon @click="handleBackClick" color="primary" style="cursor: pointer">mdi-arrow-left</v-icon>
        <h2>Appeal {{ appealId }}</h2>
        <v-spacer />
        <h3>Client id: {{ this.clientId }}</h3>
      </v-col>
      <v-col cols="12">
        <v-divider class="mb-6" />
      </v-col>
    </v-row>
    <div v-if="appealLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row justify="space-between" v-if="appeal.denial_ligitation_date">
        <v-col cols="12" md="3">
          <Countdowns :bgColor="getTimerBg()" :start-date="appeal.denial_ligitation_date"  :end-date="appealTimer" :compact="true" :title="'Appeal Timer'" />
        </v-col>
        <v-col cols="12" md="8">
          <Countdowns :bgColor="getTimerBg()" :start-date="appeal.denial_ligitation_date" :end-date="litigationTimer" :title="'Litigation Timer'" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Uploaded denial letter
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <DenialLetter :appeal="appeal" @refetch="getAppeals" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Appeal letter download
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-center">
                  <v-radio-group
                    v-model="radioRow"
                    row
                  >
                    <v-radio
                      label="Revenue reduction"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Not Operating Trade or Business"
                      value="2"
                    ></v-radio>
                    <v-radio
                      label="No Orders"
                      value="3"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <v-btn block :loading="generatingLetters" @click="openDialog" color="primary">GENERATE</v-btn>
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-btn
                      block v-if="appeal.letter_x_download_url" class="mt-5"
                      @click="downloadFile(appeal.letter_x_download_url, 'Letter-')"
                      color="primary"
                    >Download Letter</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      block v-if="appeal.letter_y_download_url" class="mt-5"
                      @click="downloadFile(appeal.letter_y_download_url, '2848-')"
                      color="primary"
                    >Download 2848</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
              <v-expansion-panel-header>
                Upload Signed Appeal and 2848
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="appeal.signed_documents.length" class="my-2">
                  <v-col cols="12" v-for="doc in appeal.signed_documents" class="pa-6 d-flex mb-2" style="border: 1px solid #E5E7EB; border-radius: 4px;">
                    {{ doc.original_file_name }}
                    <v-spacer />
                    <a
                      :download="doc.original_file_name"
                      :href="doc.url"
                      target="_blank"
                      class="ml-2"
                      type="button"
                      title="Download file"
                    >
                      <v-icon>
                        mdi-download
                      </v-icon>
                    </a>
                  </v-col>
                  <v-col cols="12">
                    <p @click="uploadMore = true" class="text-center text-decoration-underline text--primary" style="cursor: pointer">Upload more</p>
                  </v-col>
                </v-row>
                <v-row v-if="!appeal.signed_documents.length || uploadMore">
                  <v-col>
                    <UploadDocument
                      :propId="'upload-signed'"
                      @upload="uploadSignedAppealAnd2848" :loading="uploadLoading" :current-user="currentUser"
                      title="Upload Signed Appeal Letter and 2848 form"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Appeal results
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="appeal.results.length" class="my-2">
                  <v-col cols="12" v-for="doc in appeal.results" class="pa-6 d-flex mb-2" style="border: 1px solid #E5E7EB; border-radius: 4px;">
                    {{ doc.original_file_name }}
                    <v-spacer />
                    <a
                      :download="doc.original_file_name"
                      :href="doc.url"
                      target="_blank"
                      class="ml-2"
                      type="button"
                      title="Download file"
                    >
                      <v-icon>
                        mdi-download
                      </v-icon>
                    </a>
                  </v-col>
                  <v-col cols="12">
                    <p @click="uploadMoreResults = true" class="text-center text-decoration-underline text--primary" style="cursor: pointer">Upload more</p>
                  </v-col>
                </v-row>
                <v-row v-if="!appeal.results.length || uploadMoreResults">
                  <v-col>
                    <UploadDocument
                      @upload="uploadAppealResult" prop-id="'upload-results'" :loading="uploadLoading" :current-user="currentUser" title="Upload Appeal Results"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="appeal.appeal_satus_id !== 1">
                  <v-col v-if="appeal.appeal_status_id === 2">
                    <v-alert type="info">
                      <span class="font-weight-bold">Appeal Approved. </span> The workflow for the client is ended.
                    </v-alert>
                  </v-col>
                  <v-col v-else-if="appeal.litigation_hold">
                    <v-alert type="warning">
                      <span class="font-weight-bold">Appeal Denied. </span> Client placed in a "Litigation Hold" status.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-btn :loading="denyLoading" @click="denyAppeal" color="error">APPEAL DENIED</v-btn>
                  </v-col>
                  <v-col cols="auto">
                     <v-btn :loading="approveLoading" @click="approveAppeal" color="primary">APPEAL APPROVED</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Document dropbox
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="appeal.document_box.length" class="my-2">
                  <v-col cols="12" v-for="doc in appeal.document_box" class="pa-6 d-flex mb-2" style="border: 1px solid #E5E7EB; border-radius: 4px;">
                    {{ doc.original_file_name }}
                    <v-spacer />
                    <a
                      :download="doc.original_file_name"
                      :href="doc.url"
                      target="_blank"
                      class="ml-2"
                      type="button"
                      title="Download file"
                    >
                      <v-icon>
                        mdi-download
                      </v-icon>
                    </a>
                  </v-col>
                  <v-col cols="12">
                    <p @click="uploadMoreDocs = true" class="text-center text-decoration-underline text--primary" style="cursor: pointer">Upload more</p>
                  </v-col>
                </v-row>
                <v-row v-if="!appeal.document_box.length || uploadMoreDocs">
                  <v-col>
                    <UploadDocument
                      @upload="uploadAppealDocument" :loading="uploadLoading" :current-user="currentUser"
                      title="Document Dropbox"
                      :prop-id="'upload-appeal'"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import AppealsService from "@/services/appeals.service";
  import DenialLetter from "@/components/Client/Appeals/DenialLetter.vue";
  import Countdowns from "@/components/Client/Appeals/Countdowns.vue";
  import ClientsService from "@/services/clients.service";
  import app from "@/App.vue";
  import AuthService from "@/services/auth.service";
  import UploadDocument from "@/components/UploadDocument.vue";

  export default {
    name: "SingleAppeal",
    computed: {
      app() {
        return app;
      },
    },
    components: { UploadDocument, Countdowns, DenialLetter },
    data() {
      return {
        radioRow: 0,
        appealId: undefined,
        appeal: {},
        uploadMore: false,
        uploadMoreResults: false,
        uploadMoreDocs: false,
        appealLoading: false,
        appealTimer: 0,
        litigationTimer: 0,
        showDialog: false,
        uploadLoading: false,
        dialogKey: 2,
        letter: "",
        client: {},
        generatingLetters: false,
        selectedMandates: [],
        approveLoading: false,
        denyLoading: false,
        mandates: [],
        headers: [
          { text: "Author", value: "author" },
          { text: "State", value: "state_code" },
          { text: "Title", value: "title" },
          { text: "Publication Date", value: "publication_date" },
        ],
        currentUser: "",
      };
    },
    methods: {
      is30DaysAfter(date1, date2) {
        const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = date2 - date1;
        const differenceInDays = differenceInMilliseconds / MILLISECONDS_IN_A_DAY;

        return differenceInDays >= 30;
      },
      getTimerBg() {
        if (this.appeal.shipped_date) {

          const litigationDate = new Date(this.denial_ligitation_date)
          const shippedDate = new Date(this.appeal.shipped_date)

          return this.is30DaysAfter(litigationDate, shippedDate) ? null : 'green'

        } else {
          return null
        }
      },
      downloadFile(url, prefix) {
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const blobUrl = URL.createObjectURL(blob)

            const fileName = prefix + this.client.legal_name + '.pdf'
            const link = document.createElement("a");
            link.href = blobUrl
            link.download = fileName
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
          })
          .catch(error => console.error("Error downloading the PDF:", error));

        // window.open(url, "_blank");
      },
      async uploadSignedAppealAnd2848(files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          await this.uploadFile(file.uploadedFile, "signed-appeal");
        }

        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Upload is submitted",
          duration: 3000,
        });
      },
      async uploadAppealResult(files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          await this.uploadFile(file.uploadedFile, "appeal-results");
        }

        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Upload is submitted",
          duration: 3000,
        });
      },
      async uploadAppealDocument(files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          await this.uploadFile(file.uploadedFile, "appeal-document");
        }

        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Upload is submitted",
          duration: 3000,
        });
      },
      async uploadFile(file, type) {
        const formData = new FormData();

        formData.append("uploaded_file", file);
        formData.append("upload_type", type);
        formData.append("appeal", this.appealId)

        this.uploadLoading = true;
        try {
          await ClientsService.uploadFileToClient(this.clientId, formData);
          await this.getAppeals()
        } finally {
          this.uploadLoading = false;
        }

      },
      getMandates() {
        AppealsService.getMandates(this.client.state).then(e => {
          this.mandates = e;
          this.selectedMandates = e;
        });
      },
      async getClient() {
        this.client = await ClientsService.getOneClient(this.clientId)
      },
      generatePdf() {
        this.generatingLetters = true;
        const model = {
          text: this.letter,
          mandates: this.selectedMandates.map(e => ({
            state: e.state_code,
            date: e.publication_date,
            name: e.title,
            author: e.author,
          })),
          type: this.radioRow,
          client_id: this.clientId,
        };
        AppealsService.generateAppealLetter(this.appealId, model).then(res => {
          this.showDialog = false;
          // window.open(res.url, "_blank");
          this.downloadFile(res.url, 'Letter-')
          this.getAppeals();
        }).finally(() => {
          this.generatingLetters = false;
        });
      },
      openDialog() {
        this.generatingLetters = true;
        AppealsService.getAppealLetter(this.appealId, this.radioRow).then(res => {
          this.letter = res;
          this.showDialog = true;
          this.dialogKey++;
        }).finally(() => {
          this.generatingLetters = false;
        });
      },
      async getAppeals() {
        this.appeal = (await AppealsService.getAppealById(this.appealId));
        if (!this.appeal || !this.appeal.appeal_deadline) {
          this.appealLoading = false;
          return;
        }
        this.appealTimer = new Date(this.appeal.appeal_deadline * 1000)

        let litigationDate = new Date(this.appeal.denial_ligitation_date);
        litigationDate.setFullYear(litigationDate.getFullYear() + 2);
        this.litigationTimer = litigationDate
      },
      handleBackClick() {
        this.$router.push(`/clients/${ this.clientId }/appeals`);
      },
      async approveAppeal() {
        this.approveLoading = true
        const payload = {
          status: 2
        }
        try {
          await AppealsService.updateAppeal(this.appealId, payload)
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: "Appeal Approved",
            duration: 3000,
          });
          await this.getAppeals()
        } finally {
          this.approveLoading = false
        }
      },

      async denyAppeal() {
        this.denyLoading = true
        const payload = {
          status: 3
        }
        try {
          await AppealsService.updateAppeal(this.appealId, payload)
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: "Appeal Denied",
            duration: 3000,
          });
          await this.getAppeals()
        } finally {
          this.denyLoading = false
        }
      }

    },
    async created() {
      this.appealLoading = true;
      this.appealId = this.$route.params.appealId;
      this.clientId = this.$route.params.clientId;
      const data = await AuthService.getCurrentUserFromCookie();
      this.currentUser = data.data;
      await this.getAppeals();
      await this.getClient();
      this.getMandates();
      this.appealLoading = false;
    },


  };
</script>
