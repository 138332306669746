<template>
  <div>
    <v-card :color="bgColor" elevation="2" style="background-color: #E99000; color: white">
      <v-card-title>
        <div style="font-size: 1rem" >{{ title }} - {{ endDate.toLocaleDateString('en-US') }}</div>
      </v-card-title>
      <v-card-text>
        <div style="color: white">
          <v-row align="center" v-if="compact">
            <v-col cols="12">
              <div class="d-flex flex-column justify-center align-center">
                <h4 class="text-h3">{{ compactDays }}</h4>
                <div>Days</div>
              </div>
            </v-col>
          </v-row>
          <v-row v-else align="center">
            <v-col cols="4">
              <div class="d-flex flex-column justify-center align-center">
                <h4 class="text-h3">{{ years }}</h4>
                <div>Years</div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex flex-column justify-center align-center">
                <h4 class="text-h3 text-bolder">{{ months }}</h4>
                <div>Months</div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex flex-column justify-center align-center">
                <h4 class="text-h3">{{ days }}</h4>
                <div>Days</div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "Countdowns",
    props: ['endDate', 'title', 'compact', 'bgColor', 'startDate'],
    data() {
      return {
        years: null,
        months: null,
        days: null,
        compactDays: null,
      }
    },
    methods: {
      updateRemaining (distance) {
        const now = new Date();
        const end = this.endDate;

        const diffTime = Math.abs(end - now)
        this.compactDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

        let years = end.getFullYear() - now.getFullYear();
        let months = end.getMonth() - now.getMonth();

        let days = end.getDate() - now.getDate();


        // Adjust for cases where the current day is after the end date's day
        days -= 1
        if (days < 0) {
          months--;
          // Get the number of days in the previous month
          const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
          days += previousMonth;
        }
        if (months < 0) {
          years--;
          months += 12;
        }
        this.years = years;
        this.months = months;
        this.days = days;
      },

    },
    mounted () {
      const currentTime = new Date()
      const distance = Math.max(this.endDate - currentTime, 0)
      this.updateRemaining(distance)
    },
  };
</script>



<style scoped>

</style>
