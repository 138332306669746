import { render, staticRenderFns } from "./DenialLetter.vue?vue&type=template&id=e260815e&scoped=true&"
import script from "./DenialLetter.vue?vue&type=script&lang=js&"
export * from "./DenialLetter.vue?vue&type=script&lang=js&"
import style0 from "./DenialLetter.vue?vue&type=style&index=0&id=e260815e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e260815e",
  null
  
)

export default component.exports