<template>
  <div>
    <ConfirmCardDialog
      :show.sync="showDialog" confirm-btn-text="Yes" cancel-btn-text="No"
      card-desc="Are you sure you want to reject the denial letter" card-title="Reject letter"
      @confirmDialog="rejectDenialLetter"
      :loading="rejectLoading"
    />
    <div class="pa-6 d-flex" style="border: 1px solid #E5E7EB; border-radius: 4px;">
      {{ appeal.denial_filename }}
      <v-spacer />
      <a
        :download="appeal.denial_filename"
        :href="appeal.denial_letter_url"
        v-show="appeal.denial_letter_id"
        target="_blank"
        class="ml-2"
        type="button"
        title="Download file"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </a>
    </div>
    <br>
    <v-row align="center" style="gap: 10px">
      <v-col cols="12" md="2">Select Year</v-col>
      <v-col>
        <v-slide-group
          show-arrows
          mandatory
          v-model="selectedYearIndex"
        >
          <v-slide-item
            v-for="year in years"
            :key="year"
            :disabled="!!appeal.denial_ligitation_date"
            v-slot="{ active, toggle }"
          >
            <v-btn
              class="mx-2"
              :input-value="active"
              active-class="primary white--text"
              depressed
              rounded
              @click="toggle"
            >
              {{ year }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <br>
    <v-row align="center" style="gap: 10px">
      <v-col cols="12" md="2">Select Quarter</v-col>
      <v-col>
        <v-slide-group
          show-arrows
          mandatory
          v-model="selectedQuarterIndex"
        >
          <v-slide-item
            v-for="(q, i) in quarters"
            :key="q"
            v-slot="{ active, toggle }"
            :disabled="!!appeal.denial_ligitation_date"
          >
            <v-btn
              class="mx-2"
              :disabled="disabledQuarterIndexes.includes(i)"
              :input-value="active"
              active-class="primary white--text"
              depressed
              rounded
              @click="toggle"
            >
              Q{{ q }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <br>
    <v-row align="center" style="gap: 10px">
      <v-col cols="12" md="2">Select Date of Letter</v-col>
      <v-col v-if="!appeal.denial_ligitation_date">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="litigationDate"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-else>
        <v-text-field
          :value="appeal.denial_ligitation_date"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <br>
    <v-row justify="end" v-if="!appeal.denial_ligitation_date">
      <v-col cols="auto">
        <v-btn color="error" @click="showDialog = true">
          Reject
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="primary" @click="handleUpdateDenialLetter" :loading="appealUpdateLoading"
        >Confirm</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import AppealsService from "@/services/appeals.service";
  import ConfirmCardDialog from "@/components/ConfirmCardDialog.vue";

  export default {
    name: "DenialLetter",
    components: { ConfirmCardDialog },
    props: ["appeal"],
    emits: ["refetch"],
    data() {
      return {
        quarters: [1, 2, 3, 4],
        years: [2020, 2021],
        litigationDate: "",
        disabledQuarterIndexes: [0, 1, 2],
        selectedQuarterIndex: 3,
        selectedYearIndex: 0,
        appealUpdateLoading: false,
        rejectLoading: false,
        showDialog: false,
      };
    },
    methods: {
      async handleUpdateDenialLetter() {
        const quarter = this.quarters[this.selectedQuarterIndex];
        const year = this.years[this.selectedYearIndex];
        const litigation_date = this.litigationDate;

        const payload = {
          quarter,
          year,
          litigation_date,
        };

        const denialId = this.appeal.denial_letter_id;


        this.appealUpdateLoading = true;

        try {
          await AppealsService.updateDenialLetter(denialId, payload);
          this.$emit("refetch");
        } finally {
          this.appealUpdateLoading = false;
        }

      },
      formatDate(date) {
        if (!date) return null;

        const [year, month, day] = date.split("-");
        return `${ month }/${ day }/${ year }`;
      },
      async rejectDenialLetter() {
        this.rejectLoading = true;
        const clientId = this.$route.params.clientId;

        try {
          await AppealsService.deleteDenialLetter(this.appeal.denial_letter_id);
          this.$router.push(`/clients/${ clientId }/appeals`);
        } finally {
          this.rejectLoading = false;
          this.showDialog = false;
        }
      },
    },
    computed: {
      computedDateFormatted() {
        return this.formatDate(this.litigationDate);
      },
    },
    watch: {
      selectedYearIndex: function(val) {
        if (val === 1) {
          this.disabledQuarterIndexes = [3];
          // this.selectedQuarterIndex = 0;
        } else {
          this.disabledQuarterIndexes = [0, 1, 2];
          this.selectedQuarterIndex = 3;
        }
      },
    },
    created() {
      const date = new Date(this.appeal.denial_ligitation_date);

      this.selectedYearIndex = this.years.indexOf(this.appeal.denial_year);
      this.selectedQuarterIndex = this.quarters.indexOf(this.appeal.denial_quarter);

    },
  };
</script>


<style scoped>
.selected {
  background-color: #1976D2 !important;
  color: white !important;
}
</style>